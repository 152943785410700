
import { defineComponent, ref, computed } from 'vue'
import { toast, Alert } from '@/dependencies/sweet-alert'
import { useStore } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'
import CptSearch from '@/components/shared/cpt-search/cpt-search.vue'

import { useTableData } from '@/components/client/cpt/features/table-data'
import { ICPT } from '@server/api/models/fe/shared'
import { createClientCpt, deleteClientProcedure } from '@/service/client.http'

export default defineComponent({
	name: 'cpt',
	components: {
		MyndshftPanel,
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
		CptSearch,
	},
	props: {
		isUploadingCSV: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const store = useStore()
		let data: any

		const selectedCPTs = computed(
			() => store.state.client.procedures.procedures
		)

		data = computed(() => {
			return store.state.client.procedures.procedures
				? store.state.client.procedures.procedures.map((item: any) => {
						return {
							ID: item.id,
							code: item.code,
							description: item.description,
						}
				  })
				: []
		})

		const { columns } = useTableData()
		const selectedCpt = ref<ICPT | null>(null)
		const totalCount = computed(() => {
			return store.state.client.procedures.procedures?.length || 0
		})
		const isSaving = ref(false)
		const isDeleting = ref(false)

		async function addCpt() {
			if (selectedCpt.value) {
				isSaving.value = true
				await createClientCpt(selectedCpt.value, store.state.client.client.id)
				store.dispatch('client/getClient', store.state.client.client.id)
				toast({ type: Alert.SUCCESS, title: 'Successfully Created Client CPT' })
				selectedCpt.value = null
				isSaving.value = false
			}
		}

		async function deleteProcedure(procedure: ICPT) {
			isDeleting.value = true
			deleteClientProcedure(procedure, store.state.client.client.id)
				.then((res: string) => {
					store.dispatch('client/getClient', store.state.client.client.id)
					toast({
						type: res.includes('Success') ? Alert.SUCCESS : Alert.ERROR,
						title: res,
					})
				})
				.catch((err: string) => {
					toast({
						type: Alert.ERROR,
						title: err,
					})
				})
				.finally(() => {
					selectedCpt.value = null
					isDeleting.value = false
				})
		}

		return {
			data,
			columns,
			selectedCpt,
			addCpt,
			deleteProcedure,
			totalCount,
			isSaving,
			isDeleting,
			selectedCPTs,
		}
	},
})
