import { render, staticRenderFns } from "./client-configuration.vue?vue&type=template&id=2113fa48&scoped=true"
import script from "./client-configuration.vue?vue&type=script&lang=ts"
export * from "./client-configuration.vue?vue&type=script&lang=ts"
import style0 from "./client-configuration.vue?vue&type=style&index=0&id=2113fa48&prod&scoped=true&lang=scss"
import style1 from "./client-configuration.vue?vue&type=style&index=1&id=2113fa48&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2113fa48",
  null
  
)

export default component.exports