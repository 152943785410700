var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container modal-container"},[_vm._v(" Edit the payer for this client. "),_c('div',{staticClass:"columns active-toggle"},[_vm._m(0),_c('div',{staticClass:"column transaction-container"},[_c('myndshft-toggle',{staticClass:"toggle-button",attrs:{"id":"client-payer-is-active-toggle-input","isReadOnly":!_vm.$canEditClientDetail()},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1)]),_c('div',{staticClass:"columns active-toggle"},[_vm._m(1),_c('div',{staticClass:"column transaction-container"},[_c('myndshft-toggle',{staticClass:"toggle-button",attrs:{"id":"client-payer-is-in-network-toggle-input","isReadOnly":!_vm.$canEditClientDetail()},model:{value:(_vm.isInNetwork),callback:function ($$v) {_vm.isInNetwork=$$v},expression:"isInNetwork"}})],1)]),_c('hr'),_vm._l((_vm.transactionsMap),function(transaction){return _c('div',{key:transaction.name,staticClass:"columns"},[_c('div',{staticClass:"column",class:{
				'sub-toggle':
					transaction.name === _vm.TransactionTypes.PRIOR_AUTH_SUBMISSION,
			}},[_vm._v(" "+_vm._s(_vm.TransactionNames.get(transaction.name))+" ")]),_c('div',{staticClass:"column transaction-container"},[(
					!transaction.isDisabledByClient && !transaction.isDisabledByPayer
				)?_c('myndshft-toggle',{staticClass:"toggle-button",attrs:{"id":`client-payer-${transaction.name}-toggle`,"isDisabled":!_vm.isActive,"isReadOnly":!_vm.$canEditClientDetail()},model:{value:(transaction.isActive),callback:function ($$v) {_vm.$set(transaction, "isActive", $$v)},expression:"transaction.isActive"}}):_c('span',{staticClass:"disabled"},[_vm._v(_vm._s(transaction.message))])],1)])}),_c('div',{staticClass:"controls"},[_c('button',{staticClass:"button is-light",attrs:{"id":"close-modal-button"},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button ms-btn ms-btn-primary",class:{
				'is-loading': _vm.isSaving,
			},attrs:{"id":"save-transactions-button","disabled":!_vm.$canEditClientDetail()},on:{"click":_vm.saveClientPayer}},[_vm._v(" Save ")])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column"},[_c('strong',[_vm._v("Active")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column"},[_c('div',[_vm._v("In Network")])])
}]

export { render, staticRenderFns }