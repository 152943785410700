import { render, staticRenderFns } from "./cpt-pricing.vue?vue&type=template&id=068d146a&scoped=true"
import script from "./cpt-pricing.vue?vue&type=script&lang=ts"
export * from "./cpt-pricing.vue?vue&type=script&lang=ts"
import style0 from "./cpt-pricing.vue?vue&type=style&index=0&id=068d146a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068d146a",
  null
  
)

export default component.exports