
import { defineComponent } from 'vue'
import { UserStatus } from '@myndshft/types-mc-api'

export default defineComponent({
	props: {
		status: String,
	},
	setup() {
		return { UserStatus }
	},
})
