
import { defineComponent, ref, watchEffect } from 'vue'
import { useStore, usePermission } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'

import { toast, Alert } from '@/dependencies/sweet-alert'
import { useFormatting } from '@/dependencies/formatting'

import { IClient } from '../../../../server/api/models/fe/client'

interface Props {
	resetClient: any
}

export default defineComponent({
	name: 'interfax',
	components: {
		MyndshftPanel,
		[ModifiedbyTooltip.name]: ModifiedbyTooltip,
	},
	props: {
		resetClient: Function,
	},
	setup(props: Props) {
		const store = useStore()
		const permission = usePermission()

		const client = ref<IClient>(store.state.client)
		const fax = ref(client.value.inboundFax)
		const isSaving = ref(false)
		const showSecret = ref(false)

		watchEffect(() => {
			fax.value = client.value.inboundFax
		})

		const title = fax.value.number
			? permission.$canEditClientDetail()
				? 'Update Client InterFAX Details'
				: 'Client InterFAX Details'
			: 'Add Client InterFAX Details'

		const save = async () => {
			isSaving.value = true
			try {
				await store.dispatch('client/updateInboundFax', {
					client: client.value.client,
					fax: fax.value,
				})
				toast({
					type: Alert.SUCCESS,
					title: 'Successfully updated the InterFAX details',
				})
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to update InterFAX details' })
				props.resetClient()
			} finally {
				isSaving.value = false
			}
		}

		const { toPhoneNumber } = useFormatting()
		const formatNumber = () => {
			const updatedNumber = toPhoneNumber(fax.value.number)
			fax.value.number = updatedNumber
		}

		return {
			title,
			fax,
			isSaving,
			showSecret,
			formatNumber,
			save,
		}
	},
})
