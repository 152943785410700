
import { defineComponent, ref } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'

import MyndshftUpload from '@/components/shared/upload/upload.vue'

export default defineComponent({
	name: 'upload-cpt-pricing',
	props: {
		clientId: String,
	},
	components: {
		[MyndshftUpload.name]: MyndshftUpload,
	},
	setup() {
		const showGuide = ref(false)

		const onSuccess = () => {
			toast({
				type: Alert.SUCCESS,
				title:
					'Successfully updated client CPT prices. Adding CPT codes to client next.',
			})
		}

		const onError = (error: any) => {
			let message =
				'Failed to update client CPT prices. Please check your file for errors.'
			if (error.response.data.error.message) {
				// Request made and server responded with custom error
				message = `${error.response.data.error.message} `
				const invalidValues = error.response.data.error.resource_id
				if (invalidValues) {
					const max = Math.min(5, invalidValues.length)
					let invalidMessage = '(' + invalidValues[0]
					for (let i = 1; i < max; i++) {
						invalidMessage += `, ${invalidValues[i]}`
					}
					if (invalidValues.length > 5) {
						invalidMessage += `, and ${invalidValues.length - 5} more)`
					} else {
						invalidMessage += ')'
					}
					message += ' ' + invalidMessage
				}
			}
			// Show specific error and capitalize first letter of the error message
			toast({
				type: Alert.ERROR,
				title: `${message.replace(/^./, message[0].toUpperCase())}`,
				timer: 120000,
				width: '500px',
			})
		}

		const onProgress = () => {
			toast({
				type: Alert.INFO,
				title: 'Still attempting to save. Please wait.',
			})
		}

		return {
			showGuide,
			onSuccess,
			onProgress,
			onError,
		}
	},
})
