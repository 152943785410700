import { reactive } from 'vue'

import { TableProps } from '@/components/table/types'
import { ICPT } from '../../../../../server/api/models/fe/shared'

export function useTableData() {
	const tableProps: TableProps<ICPT> = reactive({
		columns: [
			{
				field: 'code',
				label: 'Code',
			},
			{
				field: 'description',
				label: 'Description',
			},
			{
				field: null,
				label: '',
				sortIsDisabled: true,
			},
		],
		data: [],
	})

	return { ...tableProps }
}
