import { getBff } from '@/service/shared.http'
import axios from 'axios'
import queryString from 'query-string'

import { IUserQueryParams } from '@/models/user/user'
import { IUserDetails } from '@server/api/models/fe/user'

export async function fetchUserManagementUsers(
	userQueryParams: IUserQueryParams
): Promise<any> {
	const qs = queryString.stringify(
		{
			page_token: userQueryParams?.pageToken || '',
			tenant_id: userQueryParams?.tenantId || '',
			status: userQueryParams?.status || '',
			statuses: userQueryParams?.statuses || '',
			email: userQueryParams?.email || '',
			name: userQueryParams?.name || '',
		},
		{ skipEmptyString: true }
	)
	const path = `${getBff()}/users${qs ? `?${qs}` : ''}`
	const response = await axios.get(path)
	return response.data
}

export async function updateUsersStatus(
	updateMask: string[],
	users: Partial<IUserDetails>[]
) {
	const path = `${getBff()}/users`
	const response = await axios.patch(path, { updateMask, users })
	return response.data
}
