
import { computed, defineComponent, ref, SetupContext, watch } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useStore, useRoute } from '@/utils/composables'

import { TenantUniqueID, UserStatus } from '@myndshft/types-mc-api'
import { userStatusOptions } from '@/models/user/user'
import { IClient, IClientUser } from '@server/api/models/fe/client'
import { IUserDetails } from '@server/api/models/fe/user'
import { IVuetifyTableHeader } from '@server/api/v2/models/fe/vuetify'

import {
	updateClientUsers,
	removeClientUserAssociations,
} from '@/service/client.http'
import { fetchAllTenantUsers } from '@/service/tenant.http'
import { updateUsersStatus } from '@/service/users.http'

import { MyndshftPanel } from '@myndshft/panel'
import UserStatusPill from '@/components/shared/user/user-status.vue'
import ConfirmationDialog from '@/components/shared/dialog/confirmation-dialog.vue'

interface IAutocompleteCustomItem {
	text: string
	value: TenantUniqueID | string
	name: string
	status: string
}

export default defineComponent({
	components: {
		MyndshftPanel,
		UserStatusPill,
		ConfirmationDialog,
	},
	props: {
		createUser: Function,
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(_, context: SetupContext) {
		const store = useStore()
		const state = ref<IClient>(store.state.client)
		const route = useRoute()
		const clientID = route.params.id

		const headers: IVuetifyTableHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'E-Mail', value: 'email' },
			{
				text: 'Status',
				value: 'status',
				width: '130px',
			},
		]
		const filterStatusOptions = userStatusOptions()

		const isLoading = computed(() => store.state.tenant.loading.users)
		// User Associations
		const tenantUsers = ref<IUserDetails[]>([])
		const tenantUsersOptions = ref<IAutocompleteCustomItem[]>([])
		const selectedUsers = ref([])
		// User Table
		const usersList = ref<IClientUser[]>(state.value.users)
		const selectedTableUsers = ref<IUserDetails[]>([])
		// Filter
		const selectedUserStatus = ref<UserStatus[]>([UserStatus.ACTIVE])
		const filteredUsersList = computed<IClientUser[]>(() => {
			if (
				selectedUserStatus.value.length > 3 ||
				!selectedUserStatus.value.length
			) {
				// All or No filters were selected
				return usersList.value
			} else {
				return usersList.value.filter(user => {
					return selectedUserStatus.value.includes(user.status)
				})
			}
		})
		// Dialog
		const showStatusDialog = ref(false)
		const dialogPage = ref(0)
		const dialogUserStatus = ref(UserStatus.ACTIVE)
		const disableUserUpdateDialog = computed(
			() => !selectedTableUsers.value.length
		)
		const dialogStatusOptions = userStatusOptions(true)
		const showRemoveAssociationsDialog = ref(false)
		// Methods
		const handleCloseDialog = () => {
			dialogPage.value = 0
			showStatusDialog.value = false
		}

		const filterTenantUsers = () => {
			const shouldFilter = !!tenantUsers.value.length
			const filteredList: IAutocompleteCustomItem[] = []
			if (shouldFilter) {
				const currentUserMap: { [key: string]: boolean } = {}
				for (const user of usersList.value) {
					currentUserMap[user.id] = true
				}
				for (const user of tenantUsers.value) {
					if (!currentUserMap[user.id]) {
						filteredList.push({
							text: user.email,
							value: user.id,
							name: user.name,
							status: user.status,
						})
					}
				}
			}
			return filteredList
		}

		const handleAddUsers = () => {
			context.emit('save', true)
			updateClientUsers(clientID, selectedUsers.value)
				.then(() => {
					selectedUsers.value = []
					store.dispatch('client/getClientUsers', state.value.client.id)
					toast({
						type: Alert.SUCCESS,
						title: `Successfully updated client associations`,
						timer: 5000,
					})
				})
				.catch(() => {
					toast({
						type: Alert.ERROR,
						title: `Failed to update client associations`,
						timer: 5000,
					})
				})
				.finally(() => {
					context.emit('save', false)
				})
		}

		const filterByNameOrEmail = (
			item: IAutocompleteCustomItem,
			search: string,
			email: string
		) => {
			const partialNameMatch =
				item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
			const partialEmailMatch =
				email.toLowerCase().indexOf(search.toLowerCase()) > -1
			return partialNameMatch || partialEmailMatch
		}

		const handleStatusDialogSave = () => {
			if (dialogPage.value === 0) {
				dialogPage.value = 1
			} else {
				showStatusDialog.value = false
				const userList = selectedTableUsers.value.map(user => ({
					id: user.id,
					status: dialogUserStatus.value,
				}))
				updateUsersStatus(['status'], userList)
					.then(() => {
						selectedTableUsers.value = []
						store.dispatch('client/getClientUsers', state.value.client.id)
						toast({
							type: Alert.SUCCESS,
							title: `Successfully updated all user statuses`,
							timer: 5000,
						})
					})
					.catch(() => {
						toast({
							type: Alert.ERROR,
							title: `Failed to update user statuses`,
							timer: 5000,
						})
					})
					.finally(() => {
						dialogPage.value = 0
					})
			}
		}

		const resetFilters = () => {
			selectedUserStatus.value = [UserStatus.ACTIVE]
		}

		const removeUserAssociations = () => {
			const userIds = selectedTableUsers.value.map(user => user.id)
			removeClientUserAssociations(state.value.client.id, userIds)
				.then(() => {
					selectedTableUsers.value = []
					store.dispatch('client/getClientUsers', state.value.client.id)
					toast({
						type: Alert.SUCCESS,
						title: `Successfully removed selected user associations`,
						timer: 5000,
					})
				})
				.catch(() => {
					toast({
						type: Alert.ERROR,
						title: `Failed to remove user associations`,
						timer: 5000,
					})
				})
				.finally(() => {
					showRemoveAssociationsDialog.value = false
				})
		}

		watch(
			() => [state.value.users, tenantUsers.value],
			([currentUsers]) => {
				usersList.value = currentUsers
				tenantUsersOptions.value = filterTenantUsers()
			}
		)

		watch(
			() => state.value.client.tenantId,
			tenantId => {
				if (tenantId) {
					fetchAllTenantUsers(tenantId).then(tUsers => {
						tenantUsers.value = tUsers
					})
				}
			}
		)

		return {
			UserStatus,
			dialogStatusOptions,
			filterStatusOptions,
			selectedUserStatus,
			filteredUsersList,
			headers,
			usersList,
			isLoading,
			dialogPage,
			selectedUsers,
			selectedTableUsers,
			tenantUsersOptions,
			showStatusDialog,
			disableUserUpdateDialog,
			dialogUserStatus,
			showRemoveAssociationsDialog,
			handleAddUsers,
			handleCloseDialog,
			filterByNameOrEmail,
			handleStatusDialogSave,
			resetFilters,
			removeUserAssociations,
		}
	},
})
