import { ref, Ref, computed, watch } from 'vue'
import { useFormatting } from '@/dependencies/formatting'
import {
	ICPT,
	ReferenceSource,
} from '../../../../../../server/api/models/fe/shared'
import { IClientPayerCPT } from '../../../../../../server/api/models/fe/client'

export function usePricingForm() {
	// data
	const selectedNewCpt = ref<ICPT | null>(null)
	const selectedCpt = ref<IClientPayerCPT | null>(null)
	const isNew = ref(false)
	const referenceSources = Object.values(ReferenceSource)
	const { toPercent } = useFormatting()
	// computed props
	const formattedPercent: Ref<string | null> = computed(() => {
		const matchTrailingZeroes = /\.?0+$/
		return selectedCpt.value?.percentage
			? (selectedCpt.value.percentage * 100)
					.toFixed(4)
					.replace(matchTrailingZeroes, '')
			: null
	})
	const displayPercent: Ref<string | null> = computed(() => {
		if (selectedCpt.value?.percentage) {
			return toPercent(selectedCpt.value.percentage)
		}
		return null
	})
	const canEnterPercent = computed(() => {
		return selectedCpt.value?.flatRate === null
	})
	const canEnterFlatRate = computed(() => {
		return (
			selectedCpt.value?.percentage === null &&
			selectedCpt.value?.referenceSource === null
		)
	})

	watch(selectedNewCpt, () => {
		// cpts in the dropdown are ICPTs, so we need to map
		// that to a IClientPayerCPT
		if (selectedNewCpt.value) {
			selectedCpt.value = {
				flatRate: null,
				percentage: null,
				referenceSource: null,
			} as IClientPayerCPT
			isNew.value = true
		} else {
			// if the cpt is deselected in the dropdown, we reset the selectedCpt
			selectedCpt.value = null
		}
	})

	function onPercentInput(value: string) {
		if (selectedCpt.value) {
			if (value) {
				selectedCpt.value.percentage = Number((Number(value) / 100).toFixed(4))
			} else {
				// if user deletes whole number, treat as null
				// this will re-enable the flatRate input if no reference source
				selectedCpt.value.percentage = null
			}
		}
	}

	function onFlatRateInput(value: string) {
		if (selectedCpt.value) {
			// if user deletes whole number, treat as null
			// this will re-enable the percent input
			if (!value) {
				selectedCpt.value.flatRate = null
			}
		}
	}

	function selectCpt(cpt: IClientPayerCPT) {
		selectedCpt.value = JSON.parse(JSON.stringify(cpt))
		isNew.value = false
	}

	function cancelEdit() {
		selectedCpt.value = null
		selectedNewCpt.value = null
	}

	return {
		selectedNewCpt,
		selectedCpt,
		isNew,
		referenceSources,
		formattedPercent,
		canEnterPercent,
		canEnterFlatRate,
		displayPercent,

		// methods
		onPercentInput,
		onFlatRateInput,
		selectCpt,
		cancelEdit,
	}
}
