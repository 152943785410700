var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('myndshft-panel',{staticClass:"ms-information"},[_c('h2',[_vm._v("Add or remove CPT code")]),_c('div',{staticClass:"columns new-cpt"},[_c('div',{staticClass:"column"},[_c('cpt-search',{attrs:{"disabled":!_vm.$canEditClientDetail(),"displayType":"main","selectedCPTs":_vm.selectedCPTs},model:{value:(_vm.selectedCpt),callback:function ($$v) {_vm.selectedCpt=$$v},expression:"selectedCpt"}})],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"button ms-btn ms-btn-primary",class:{
							'not-allowed': !_vm.$canEditClientDetail(),
							'is-loading': _vm.isSaving,
						},attrs:{"id":"add-new-cpt-button","disabled":!_vm.$canEditClientDetail() || !_vm.selectedCpt || _vm.isUploadingCSV,"data-tooltip":""},on:{"click":_vm.addCpt}},[_vm._v(" Add New CPT ")])])])]),_c('div',{staticClass:"count-container"},[_vm._v("Total Codes: "+_vm._s(_vm.totalCount))]),_c('myndshft-table',{attrs:{"data":_vm.data,"columns":_vm.columns,"hoverable":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('myndshft-table-cell',[_vm._v(_vm._s(row.code))]),_c('myndshft-table-cell',[_vm._v(_vm._s(row.description))]),_c('myndshft-table-cell',[_c('button',{staticClass:"icon-button",class:{
							'icon-button': true,
							'not-allowed': !_vm.$canEditClientDetail(),
							'is-loading': _vm.isDeleting,
						},attrs:{"id":"delete-procedure-button","disabled":!_vm.$canDeleteClientDetail()},on:{"click":function($event){return _vm.deleteProcedure(row)}}},[_c('fa-icon',{staticClass:"item-icon delete-icon",class:{ disabled: !_vm.$canDeleteClientDetail() },attrs:{"icon":"trash-alt"}})],1)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }