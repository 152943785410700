
import { defineComponent, ref, SetupContext, PropType, watchEffect } from 'vue'

import MyndshftToggle from '@/components/shared/toggle/toggle.vue'

import {
	ClientTransactionTypes,
	IClientPayer as IClientPayerMC,
	IClientTransaction,
} from '@server/api/models/fe/client'
import {
	TransactionTypes,
	TransactionNames,
} from '@server/api/models/fe/shared'
import { fetchClientPayerTransactions } from '@/service/client.http'
import { Alert, toast } from '@/dependencies/sweet-alert'

interface IClientPayerTransaction extends IClientTransaction {
	isDisabledByClient: boolean
	isDisabledByPayer: boolean
	message: string
}

export default defineComponent({
	name: 'edit-transactions',
	components: {
		[MyndshftToggle.name]: MyndshftToggle,
	},
	props: {
		clientPayer: Object as PropType<IClientPayerMC>,
		clientTransactions: Array as PropType<IClientTransaction[]>,
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context: SetupContext) {
		const initialTransactionState = (): IClientPayerTransaction[] => [
			{
				name: TransactionTypes.ELIGIBILITY,
				isActive: true,
				isDisabledByClient: false,
				isDisabledByPayer: false,
				message: '',
			},
			{
				name: TransactionTypes.PRIOR_AUTH,
				isActive: true,
				isDisabledByClient: false,
				isDisabledByPayer: false,
				message: '',
			},
			{
				name: TransactionTypes.PRIOR_AUTH_SUBMISSION,
				isActive: true,
				isDisabledByClient: false,
				isDisabledByPayer: false,
				message: '',
			},
			{
				name: TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION,
				isActive: true,
				isDisabledByClient: false,
				isDisabledByPayer: false,
				message: '',
			},
			{
				name: TransactionTypes.PATIENT_RESPONSIBILITY,
				isActive: true,
				isDisabledByClient: false,
				isDisabledByPayer: false,
				message: '',
			},
		]

		const createTransactionMap = (transactions: IClientPayerTransaction[]) => {
			const map: Record<string, IClientPayerTransaction> = {}
			transactions.forEach(transaction => {
				map[transaction.name] = transaction
			})
			return map
		}

		const isActive = ref<boolean>(false)
		const isInNetwork = ref<boolean>(false)
		const payerTransactions = ref<IClientTransaction[]>([])
		const clientTransactions = ref<IClientTransaction[]>([])
		const disabledClientPayerTransactions = ref<TransactionTypes[]>([])
		const transactionsMap = ref<Record<string, IClientPayerTransaction>>({})

		const setDisabledMessage = (transaction: IClientPayerTransaction): void => {
			if (transaction.isDisabledByClient && !transaction.isDisabledByPayer) {
				transaction.message = 'Disabled by Client'
			} else if (
				transaction.isDisabledByPayer &&
				!transaction.isDisabledByClient
			) {
				transaction.message = 'Disabled by Payer'
			} else {
				transaction.message = 'Disabled by Client and Payer'
			}
		}

		const setDisabledStatuses = (): void => {
			// set isActive false if listed in disable client payers transactions
			disabledClientPayerTransactions.value.forEach(name => {
				if (Object.prototype.hasOwnProperty.call(transactionsMap.value, name)) {
					transactionsMap.value[name].isActive = false
				}
			})
			// set isDisabledByClient if client transaction is not active
			clientTransactions.value.forEach(cTransaction => {
				const name =
					cTransaction.name === ClientTransactionTypes.PRIOR_AUTH_DETERMINATION
						? TransactionTypes.PRIOR_AUTH
						: cTransaction.name
				if (Object.prototype.hasOwnProperty.call(transactionsMap.value, name)) {
					transactionsMap.value[name].isDisabledByClient =
						!cTransaction.isActive
				}
			})
			// set isDisabledByPayer if payer transaction is not active
			payerTransactions.value.forEach(pTransaction => {
				if (
					Object.prototype.hasOwnProperty.call(
						transactionsMap.value,
						pTransaction.name
					)
				) {
					transactionsMap.value[pTransaction.name].isDisabledByPayer =
						!pTransaction.isActive
				}
			})
			// set message to show if any disabled
			Object.keys(transactionsMap.value).forEach(key => {
				setDisabledMessage(transactionsMap.value[key])
			})
		}

		const convertToClientTransactions = (): IClientTransaction[] => {
			const transactions: IClientTransaction[] = []
			Object.keys(transactionsMap.value).forEach(key => {
				transactions.push({
					name: transactionsMap.value[key].name,
					isActive: transactionsMap.value[key].isActive,
				})
			})
			return transactions
		}

		const saveClientPayer = () => {
			context.emit('save', {
				...props.clientPayer,
				...{
					isActive: isActive.value,
					transactions: convertToClientTransactions(),
					isInNetwork: isInNetwork.value,
				},
			})
		}

		const closeModal = () => {
			context.emit('close')
		}

		// if PAR is OFF then PAS must be OFF
		watchEffect(() => {
			if (
				Object.prototype.hasOwnProperty.call(
					transactionsMap.value,
					TransactionTypes.PRIOR_AUTH_SUBMISSION
				) &&
				!transactionsMap.value.PRIOR_AUTH.isActive
			) {
				transactionsMap.value.PRIOR_AUTH_SUBMISSION.isActive = false
			}
		})

		watchEffect(() => {
			if (!isActive.value) {
				Object.keys(transactionsMap.value).forEach(key => {
					transactionsMap.value[key].isActive = false
				})
			}
		})

		watchEffect(() => {
			isActive.value = props.clientPayer?.isActive ?? false
			isInNetwork.value = props.clientPayer?.isInNetwork ?? false

			fetchClientPayerTransactions(
				props.clientPayer?.clientId || '',
				props.clientPayer?.payerId || ''
			)
				.then(results => {
					disabledClientPayerTransactions.value =
						results.clientPayer.disabledTransactions || []
					payerTransactions.value = results.payer || []
					clientTransactions.value = results.client || []
					transactionsMap.value = createTransactionMap(
						initialTransactionState()
					)
					setDisabledStatuses()
				})
				.catch(() => {
					toast({
						type: Alert.ERROR,
						title: 'Error while fetching payer transactions',
					})
				})
		})

		return {
			isActive,
			isInNetwork,
			transactionsMap,
			closeModal,
			saveClientPayer,
			TransactionTypes,
			TransactionNames,
		}
	},
})
