
import { ref, Ref, watch, defineComponent, computed, watchEffect } from 'vue'
import { useStore } from '@/utils/composables'

import { MyndshftModal } from '@myndshft/modal'
import { MyndshftPanel } from '@myndshft/panel'
import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'
import ClientPayerRow from '@/components/client/payer/edit-row.vue'
import MyndshftSelect from '@/components/form/select.vue'
import BulkTransactions from './modals/bulk-transactions.vue'

import {
	IClientPayerListItem,
	IClientPayer,
} from '@server/api/models/fe/client'
import { ClientUniqueID } from '@myndshft/types-mc-api'
import { debounce } from '@/utils/helpers'
import { TableColumns } from '@/components/table/types'

export default defineComponent({
	name: 'payer',
	components: {
		MyndshftPanel,
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
		[MyndshftSelect.name]: MyndshftSelect,
		MyndshftModal,
		ClientPayerRow,
		BulkTransactions,
	},
	setup() {
		const store = useStore()
		const isInNetwork = ref(false)
		const clientPayers: Ref<IClientPayer[]> = ref(
			store.state.client.clientPayers.payers
		)
		const payerCount = computed(() => {
			return store.state.client.clientPayers.total
		})
		const selectedCount = computed(
			() => clientPayers.value.filter(payer => payer.isSelected).length
		)
		const showUpdateSelectedModal = ref(false)
		const selectAll = ref(false)
		const columns = computed<TableColumns<IClientPayer>>(() => [
			{
				field: null,
				label: 'show-select',
				select: {
					onSelect: (value: boolean) => {
						selectAll.value = value
					},
					isSelected: selectAll.value,
				},
				sortIsDisabled: true,
				width: '50px',
			},
			{
				field: 'name',
				label: 'Payer',
			},
			{
				field: 'isActive',
				label: 'Status',
			},
			{
				field: 'isInNetwork',
				label: 'Network Status',
			},
			{
				field: null,
				label: 'Codes',
				sortIsDisabled: true,
			},
		])
		const searchTerm = ref('')
		const clientId: Ref<ClientUniqueID> = ref(store.state.client.ID)
		const clientTransactions = ref(store.state.client.transactions)

		const onSearch = () => {
			clientPayers.value = searchTerm.value
				? store.state.client.clientPayers.payers.filter(
						(payer: IClientPayerListItem) =>
							payer.name.toLowerCase().includes(searchTerm.value.toLowerCase())
				  )
				: store.state.client.clientPayers.payers
		}

		const debouncedSearch = debounce(onSearch, 500)

		const updateAllSelected = (selected: boolean) => {
			clientPayers.value = clientPayers.value.map(payer => {
				payer.isSelected = selected
				return payer
			})
		}

		const handleClearSelected = () => {
			if (selectAll.value) {
				selectAll.value = false
			} else {
				updateAllSelected(false)
			}
		}

		const handleUpdateSelected = () => {
			showUpdateSelectedModal.value = true
		}

		const updateSelected = (payer: IClientPayer) => {
			const index = clientPayers.value.findIndex(
				p => p.payerId === payer.payerId
			)
			// copy required to trigger reactive states when updating ref
			const copy = [...clientPayers.value]
			copy[index] = payer
			clientPayers.value = copy
		}

		watchEffect(() => {
			clientId.value = store.state.client.client.id
			clientTransactions.value = store.state.client.transactions
		})

		watch(
			() => store.state.client.clientPayers.payers,
			() => {
				onSearch()
			}
		)

		watch(selectAll, selected => {
			updateAllSelected(selected)
		})

		return {
			clientPayers,
			columns,
			clientId,
			clientTransactions,
			payerCount,
			searchTerm,
			isInNetwork,
			showUpdateSelectedModal,
			selectedCount,
			// methods
			debouncedSearch,
			handleClearSelected,
			handleUpdateSelected,
			updateSelected,
		}
	},
})
