var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container modal-container"},[(!_vm.selectedCpt || _vm.isNew)?_c('div',{staticClass:"columns"},[_c('cpt-search',{staticClass:"column is-half",attrs:{"label":"Add CPT Code","onlyClientCPTs":"","selectedCPTs":_vm.cpts,"disabled":!_vm.$canEditReferenceRate(),"displayType":"modal"},model:{value:(_vm.selectedNewCpt),callback:function ($$v) {_vm.selectedNewCpt=$$v},expression:"selectedNewCpt"}})],1):_vm._e(),(_vm.selectedCpt)?_c('div',{staticClass:"columns"},[(!_vm.isNew)?_c('div',{staticClass:"column is-one-half"},[_c('span',[_vm._v(_vm._s(_vm.selectedCpt.code))]),(_vm.selectedCpt.description.length > 0)?_c('span',[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.selectedCpt.description))])]):_vm._e()]):_vm._e(),_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveCpt.apply(null, arguments)}}},[(_vm.selectedCpt)?_c('div',{staticClass:"columns pricing-form"},[_c('div',{staticClass:"column is-one-quarter"},[_c('ValidationProvider',{ref:"aliasValidator",attrs:{"name":"percentage","rules":{
							percentageRequired: _vm.canEnterPercent,
							percentageValue: _vm.canEnterPercent,
							percentageInteger: _vm.canEnterPercent,
						}}},[_c('ms-input',{attrs:{"id":"add-percentage-input","value":_vm.formattedPercent,"disabled":!_vm.canEnterPercent,"maxlength":3,"nospace":true,"label":"Percentage","placeholder":"Add Percentage","required":_vm.canEnterPercent},on:{"input":_vm.onPercentInput}})],1)],1),_c('div',{staticClass:"column is-one-quarter"},[_c('label',{staticClass:"select-label"},[_vm._v(" Reference Source "),(_vm.canEnterPercent)?_c('span',{staticClass:"is-required"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"name":"source","rules":{ referenceSource: _vm.canEnterPercent }}},[_c('myndshft-select',{attrs:{"placeholder":'Select Reference Source',"options":_vm.referenceSources,"id":"reference-source-input","disabled":!_vm.canEnterPercent,"required":_vm.canEnterPercent},model:{value:(_vm.selectedCpt.referenceSource),callback:function ($$v) {_vm.$set(_vm.selectedCpt, "referenceSource", $$v)},expression:"selectedCpt.referenceSource"}})],1)],1),_c('div',{staticClass:"column is-2"},[_c('div',{staticClass:"is-divider-vertical",attrs:{"data-content":"OR"}})]),_c('div',{staticClass:"column is-one-quarter"},[_c('ValidationProvider',{ref:"aliasValidator",attrs:{"name":"flatrate","rules":{
							flatRateRequired: _vm.canEnterFlatRate,
							flatRateValue: _vm.canEnterFlatRate,
						}}},[_c('ms-input',{attrs:{"disabled":!_vm.canEnterFlatRate,"label":"Flat Rate","maxlength":10,"placeholder":"Add Flat Rate","nospace":true,"required":_vm.canEnterFlatRate},on:{"input":_vm.onFlatRateInput},model:{value:(_vm.selectedCpt.flatRate),callback:function ($$v) {_vm.$set(_vm.selectedCpt, "flatRate", $$v)},expression:"selectedCpt.flatRate"}})],1)],1)]):_vm._e(),_c('div',[_c('p',{staticClass:"validation-error"},[_vm._v(_vm._s(_vm.errors))])]),(_vm.selectedCpt)?_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button ms-btn ms-btn-primary",class:{
						'is-loading': _vm.isSaving,
					},attrs:{"id":"add-cpt-pricing-button","type":"submit","disabled":invalid}},[(_vm.isNew)?_c('span',[_vm._v("Add CPT Pricing")]):_c('span',[_vm._v("Edit CPT Pricing")])]),_c('button',{staticClass:"button",on:{"click":_vm.cancelEdit}},[_vm._v("Cancel")])]):_vm._e()])]}}])}),_c('div',{staticClass:"count-container"},[_vm._v("Total Codes: "+_vm._s(_vm.totalCount))]),_c('myndshft-table',{attrs:{"columns":_vm.tableColumns,"data":_vm.cpts,"loader":_vm.isLoadingCpts,"customId":"cpt-pricing","hoverable":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('myndshft-table-cell',[(_vm.$canEditReferenceRate())?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.selectCpt(row)}}},[_vm._v(_vm._s(row.code))]):_c('span',[_vm._v(_vm._s(row.code))])]),_c('myndshft-table-cell',[_vm._v(_vm._s(_vm.trimLabel(row.description, 30)))]),_c('myndshft-table-cell',[(row.percentage)?_c('span',[_vm._v(_vm._s(_vm.toPercent(row.percentage)))]):_c('span',[_vm._v("--")])]),_c('myndshft-table-cell',[(row.referenceSource)?_c('span',[_vm._v(_vm._s(row.referenceSource))]):_c('span',[_vm._v("--")])]),_c('myndshft-table-cell',[(row.flatRate)?_c('span',[_vm._v(_vm._s(_vm.toCurrency(row.flatRate)))]):_c('span',[_vm._v("--")])]),_c('myndshft-table-cell',[_c('button',{staticClass:"icon-button",class:{
						'icon-button': true,
						'not-allowed': !_vm.$canDeleteClientDetail(),
						'is-loading': _vm.isDeleting,
					},attrs:{"id":"delete-pricing-button","disabled":!_vm.$canDeleteClientDetail()},on:{"click":function($event){return _vm.deleteCpt(row.code)}}},[_c('fa-icon',{staticClass:"item-icon delete-icon",class:{ disabled: !_vm.$canDeleteClientDetail() },attrs:{"icon":"trash-alt"}})],1)])]}}])}),_c('div',{staticClass:"controls"},[_c('button',{staticClass:"button is-light",attrs:{"id":"close-modal-button"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }