
import {
	defineComponent,
	onMounted,
	SetupContext,
	ref,
	watchEffect,
	computed,
	PropType,
} from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'

import {
	createOrUpdateClientPayerCpt,
	deleteClientPayerCpt,
	fetchClientPayerPricing,
} from '@/service/client.http'

import CptSearch from '@/components/shared/cpt-search/cpt-search.vue'

import MsInput from '@/components/form/input.vue'
import { usePricingForm } from '@/components/client/payer/modals/features/pricing-form'
import MyndshftSelect from '@/components/form/select.vue'
import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'

import { useFormatting } from '@/dependencies/formatting'

import { IClientPayer, IClientPayerCPT } from '@server/api/models/fe/client'

const tableColumns = [
	{ field: 'code', label: 'Code' },
	{ field: 'description', label: 'Description' },
	{ field: 'percentage', label: '%' },
	{ field: 'referenceSource', label: 'Source' },
	{ field: 'flatRate', label: 'Flat Rate' },
	{ field: null, label: '', sortIsDisabled: true },
]

export default defineComponent({
	name: 'client-payer-cpt-pricing',
	components: {
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
		[MyndshftSelect.name]: MyndshftSelect,
		CptSearch,
		MsInput,
	},
	props: {
		clientPayer: { type: Object as PropType<IClientPayer>, required: true },
	},
	setup(props, context: SetupContext) {
		const clientPayer = ref<IClientPayer>(props.clientPayer)
		const cpts = ref<IClientPayerCPT[]>([])
		const isLoadingCpts = ref(false)
		const isSaving = ref(false)
		const isDeleting = ref(false)
		const { trimLabel, toPercent, toCurrency } = useFormatting()
		const {
			selectedNewCpt,
			isNew,
			referenceSources,
			formattedPercent,
			canEnterPercent,
			canEnterFlatRate,
			displayPercent,
			selectedCpt,
			// methods
			onPercentInput,
			onFlatRateInput,
			selectCpt,
			cancelEdit,
		} = usePricingForm()

		const form = ref(null)
		const validationObserver = ref(null)
		const totalCount = ref(0)

		watchEffect(() => {
			clientPayer.value = props.clientPayer
		})

		onMounted(() => {
			isLoadingCpts.value = true
			fetchCpts()
		})

		const fetchCpts = () => {
			fetchClientPayerPricing(
				clientPayer.value.clientId as any,
				clientPayer.value.payerId as any
			)
				.then((clientPayerPricing: any[]) => {
					const mappedCpts = clientPayerPricing.map((item: any) => {
						return Object.assign(item, {
							code: item.procedureCode,
							description: item.shortDescription,
							percentage: item.percentage || null,
							referenceSource: item.referenceSource || null,
							flatRate: item.flatRate || null,
						})
					})
					cpts.value = mappedCpts
					isLoadingCpts.value = false
					isSaving.value = false
					totalCount.value = mappedCpts.length
				})
				.catch(err => {
					toast({
						type: Alert.ERROR,
						title: 'Error Fetching Client Payer CPTs',
					})
					console.warn(err)
				})
		}

		const saveCpt = () => {
			if (selectedCpt.value) {
				isSaving.value = true
				const procedureCode = selectedNewCpt.value?.code
					? selectedNewCpt.value?.code
					: selectedCpt.value.procedureCode
				createOrUpdateClientPayerCpt(
					clientPayer.value.clientId as any,
					clientPayer.value.payerId as any,
					procedureCode,
					selectedCpt.value as any
				)
					.then(() => {
						selectedCpt.value = null
						toast({
							type: Alert.SUCCESS,
							title: 'Successfully updated Payer CPT Pricing',
						})
						fetchCpts()
					})
					.catch(err => {
						toast({
							type: Alert.ERROR,
							title: 'Error updating  Payer CPT Pricing',
						})
						console.warn(err)
					})
					.finally(() => {
						isSaving.value = false
					})
			}
		}

		const deleteCpt = (code: string) => {
			isDeleting.value = true
			deleteClientPayerCpt(
				clientPayer.value.clientId as any,
				clientPayer.value.payerId as any,
				code
			)
				.then(() => {
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully delete Payer CPT Pricing',
					})
					fetchCpts()
				})
				.catch(err => {
					toast({
						type: Alert.ERROR,
						title: 'Error deleting Payer CPT Pricing',
					})
					console.warn(err)
				})
				.finally(() => {
					isDeleting.value = false
				})
		}

		const closeModal = () => {
			context.emit('close')
		}

		const errors = computed(() => {
			let message = ''
			if (
				validationObserver.value &&
				(validationObserver.value as any).errors
			) {
				const errorList = (validationObserver.value as any).errors
				if (errorList.flatrate && errorList.percentage && errorList.source) {
					message = errorList.flatrate
						.join(' ')
						.concat(
							' ',
							errorList.percentage.join(' '),
							' ',
							errorList.source.join(' ')
						)
				}

				return message
			}

			return message
		})

		return {
			// data
			tableColumns,
			cpts,
			selectedNewCpt,
			selectedCpt,
			formattedPercent,
			referenceSources,
			canEnterPercent,
			canEnterFlatRate,
			isNew,
			displayPercent,
			isLoadingCpts,
			isSaving,
			isDeleting,
			form,
			validationObserver,
			errors,
			totalCount,

			// methods
			selectCpt,
			trimLabel,
			toPercent,
			toCurrency,
			onPercentInput,
			onFlatRateInput,
			cancelEdit,
			saveCpt,
			deleteCpt,
			closeModal,
		}
	},
})
