
import { defineComponent, ref, SetupContext, PropType } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useStore } from '@/utils/composables'

import { IClientPayer } from '@server/api/models/fe/client'
import { updateClientPayer } from '@/service/client.http'

import MyndshftToggle from '@/components/shared/toggle/toggle.vue'
import { ClientUniqueID } from '@myndshft/types-mc-api'

export default defineComponent({
	name: 'BulkTransactions',
	components: {
		[MyndshftToggle.name]: MyndshftToggle,
	},
	props: {
		clientPayers: {
			type: Array as PropType<IClientPayer[]>,
			default: () => [],
		},
		clientId: {
			type: String as unknown as PropType<ClientUniqueID>,
			required: true,
		},
		isDisabled: Boolean,
	},
	setup(props, context: SetupContext) {
		const store = useStore()

		const isInNetwork = ref(false)
		const isSaving = ref(false)

		const saveClientPayers = () => {
			isSaving.value = true
			context.emit('close')

			const selectedRequests = props.clientPayers
				.filter(payer => payer?.isSelected)
				.map(payer => {
					payer.isInNetwork = isInNetwork.value
					return updateClientPayer(payer, props.clientId)
				})

			Promise.all(selectedRequests)
				.then(async () => {
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully updated the selected payer(s)',
					})
					await store.dispatch('client/getClient', store.state.client.client.id)
				})
				.catch(error => {
					toast({
						type: Alert.ERROR,
						title: 'Failed to update the client payer',
					})
					console.warn(error)
				})
				.finally(() => {
					isSaving.value = false
				})
		}

		const closeModal = () => {
			context.emit('close')
		}

		return {
			isInNetwork,
			isSaving,
			closeModal,
			saveClientPayers,
		}
	},
})
