
import { defineComponent } from 'vue'

import Client from '@/components/client/client.vue'

export default defineComponent({
	components: {
		Client,
	},
	setup() {
		return {}
	},
})
