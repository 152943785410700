
import {
	computed,
	defineComponent,
	ref,
	watch,
	Ref,
	onMounted,
	watchEffect,
} from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { MyndshftPanel } from '@myndshft/panel'

import { useFormatting } from '@/dependencies/formatting'
import { fetchTenantList } from '@/service/tenant.http'
import { deleteClient } from '@/service/client.http'
import { fetchPlacesOfService } from '@/service/shared.http'
import { getV2Route } from '@/service/shared.http'
import { useStore, useRouter, usePermission } from '@/utils/composables'

import { IPlaceOfService, IServiceType } from '@server/api/models/fe/shared'
import { ITenantDetails } from '@server/api/models/fe/tenant'
import { states } from '@/components/shared/configuration/states'
import { IClientTransaction } from '@server/api/models/fe/client'

import LastModified from '@/components/shared/last-modified/last-modified.vue'
import ConfirmationDialog from '@/components/shared/dialog/confirmation-dialog.vue'
import ClientTransactions from '@/components/client/client-transactions.vue'

export default defineComponent({
	props: {
		updateConfig: { type: Function, required: true },
		title: String,
		isSaving: {
			type: Boolean,
			default: false,
		},
		clientTitleName: { type: String, default: '' },
	},
	components: {
		MyndshftPanel,
		LastModified,
		ConfirmationDialog,
		ClientTransactions,
	},
	setup(props) {
		const store = useStore()
		const router = useRouter()
		const permission = usePermission()

		const serviceTypes: Ref<IServiceType[]> = ref(
			store.state.serviceTypes.types
		)
		const canMoveTenant = computed(() => !store.state.client.users.length)
		const showRemoveDialog = ref(false)
		const showSaveDialog = ref(false)
		const removeDialogTitle = computed(() => {
			return props.clientTitleName
				? `Are you sure you want to remove ${props.clientTitleName}?`
				: 'Are you sure you want to continue?'
		})
		const posOptions = ref<IPlaceOfService[]>([])
		const config = ref(store.state.client.client)

		const updateTransactions = (updatedTransactions: IClientTransaction[]) => {
			config.value.transactions = updatedTransactions
		}

		const onSave = async () => {
			props.updateConfig(
				config.value,
				config.value.transactions,
				config.value.inboundFax
			)
			showSaveDialog.value = false
		}

		const isRequired = computed(() => {
			// If there is a value, user is filling out form so require validation
			return Object.values(config.value.address).some(Boolean)
		})

		const onZipCode = (value: string) => {
			const { toZipCode } = useFormatting()
			config.value.address.zip = toZipCode(value)
		}

		const isEditingTenant = ref(false)
		const tenants = ref<ITenantDetails[]>([])
		const tenantName = ref('')

		const isRequiredName = computed(() => ({
			clientName: !config.value?.firstName && !config.value?.lastName,
			firstName: !config.value?.name || !!config.value?.lastName,
			lastName: !config.value?.name || !!config.value?.firstName,
		}))

		const canEditTenant =
			permission.$canEditTenantDetail() && permission.$canEditClientDetail()

		const canSaveTransactions = computed(() => {
			const canSave = store.getters['client/canSaveTransactions']
			if (!canSave) {
				toast({
					type: Alert.INFO,
					title:
						'Using Prior Auth Submission also requires Prior Auth Requirements.',
					timer: 5000,
				})
			}
			return canSave
		})

		const hasUsers = computed(() => {
			return store.getters['client/hasUsers']
		})

		const handleRemoveClient = () => {
			deleteClient(config.value.id)
				.then(() => {
					showRemoveDialog.value = false
					toast({
						type: Alert.SUCCESS,
						title:
							'Successfully removed client. Redirecting to Client Configuration.',
						timer: 5000,
					})
					store.dispatch('client/reset')
					setTimeout(() => {
						router.push('/client/list')
					}, 500)
				})
				.catch(error => {
					const message = error.response.data.message
						? `${error.response.data.message} Unkown error occured`
						: 'Unknown error occured'
					toast({
						type: Alert.ERROR,
						title: message,
						timer: 5000,
					})
				})
		}

		const formatFederalTaxId = () => {
			const taxId = config.value.federalTaxId
			if (taxId) {
				config.value.federalTaxId = taxId.replace(/-/g, '')
			}
		}

		watchEffect(() => {
			config.value = store.state.client.client
			config.value.transactions = store.state.client.transactions
			config.value.inboundFax = store.state.client.inboundFax
			serviceTypes.value = store.state.serviceTypes.types
		})

		watch(
			() => [config.value.placeOfServiceCode, posOptions.value],
			([posCode]) => {
				if (posCode && posOptions.value.length) {
					config.value.placeOfService =
						posOptions.value.find(
							pos => pos.code === config.value.placeOfServiceCode
						) || null
				}
			}
		)

		watchEffect(() => {
			tenantName.value = tenants.value.filter(
				tenant => tenant.id === config.value.tenantId
			)[0]?.name
		})

		watch(
			() => config.value.status,
			cStatus => {
				if (cStatus === 'INACTIVE') {
					const inactiveTransactions =
						config.value.transactions?.map((t: any) => {
							t.isActive = false
							return t
						}) || []
					config.value.transactions = inactiveTransactions
				}
			}
		)

		onMounted(async () => {
			tenants.value = (await fetchTenantList()).tenants
			fetchPlacesOfService().then(optionsResponse => {
				posOptions.value = optionsResponse
			})

			store.dispatch('serviceTypes/fetchServiceTypes').catch(error => {
				toast({
					type: Alert.ERROR,
					title: 'Failed to get client service types',
				})
				console.warn(error)
			})
		})

		return {
			states,
			configTitle: props.title,
			posOptions,
			config,
			isRequiredName,
			serviceTypes,
			tenants,
			tenantName,
			isEditingTenant,
			isRequired,
			canEditTenant,
			canMoveTenant,
			canSaveTransactions,
			showRemoveDialog,
			removeDialogTitle,
			hasUsers,
			showSaveDialog,
			onSave,
			onZipCode,
			handleRemoveClient,
			updateTransactions,
			formatFederalTaxId,
			getV2Route,
		}
	},
})
