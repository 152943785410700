import { UserStatus } from '@myndshft/types-mc-api'

export interface IUserQueryParams {
	pageToken?: string | null
	tenantId?: string | null
	externalId?: string | null
	status?: string | null
	statuses?: string[] | null
	email?: string | null
	name?: string | null
}

/**
 * Returns the User Status options
 *
 * @remarks
 * This method is used exclusively to provide User Options for Vuetify components
 *
 * @param disableUnverified - Boolean when true will disable Unverified option
 *
 */
export const userStatusOptions = (disableUnverified = false) => [
	{ text: 'Active', value: UserStatus.ACTIVE },
	{ text: 'Inactive', value: UserStatus.INACTIVE },
	{ text: 'Terminated', value: UserStatus.TERMINATED },
	{
		text: 'Unverified',
		value: UserStatus.UNVERIFIED,
		disabled: disableUnverified,
	},
]
