
import { defineComponent, ref, computed, SetupContext, PropType } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { MyndshftModal } from '@myndshft/modal'
import { useStore } from '@/utils/composables'
import { getV2Route } from '@/service/shared.http'

import EditTransactions from '@/components/client/payer/modals/transactions.vue'
import CptPricing from '@/components/client/payer/modals/pricing.vue'
import MyndshftTableCell from '@/components/table/cell.vue'

import {
	IClientPayerMC,
	IClientTransaction,
} from '@server/api/models/fe/client'

export default defineComponent({
	name: 'client-payer-row',
	components: {
		[MyndshftTableCell.name]: MyndshftTableCell,
		[EditTransactions.name]: EditTransactions,
		CptPricing,
		MyndshftModal,
	},
	props: {
		row: Object as PropType<IClientPayerMC>,
		clientId: String,
		clientTransactions: Array as PropType<IClientTransaction[]>,
	},
	setup(props, context: SetupContext) {
		const store = useStore()

		const clientPayer = computed(() => props.row)
		const isEditingTransactions = ref(false)
		const isEditingPricing = ref(false)
		const isEditingAliases = ref(false)
		const isEditingPortals = ref(false)
		const isSavingTransactions = ref(false)

		async function removeClientPayer() {
			console.warn(
				'removeClientPayer will not work as it requires ClientPayerID'
			)
		}

		async function saveClientPayer(payer: IClientPayerMC) {
			isSavingTransactions.value = true
			try {
				await store.dispatch('client/updateClientPayer', {
					id: props.clientId,
					config: payer,
				})
				isEditingTransactions.value = false
				toast({
					type: Alert.SUCCESS,
					title: 'Successfully updated the client payer',
				})
				await store.dispatch('client/getClient', store.state.client.client.id)
			} catch (error) {
				toast({
					type: Alert.ERROR,
					title: 'Failed to update the client payer',
				})
				console.warn(error)
			} finally {
				isSavingTransactions.value = false
			}
		}

		const handleOnSelect = (value: boolean) => {
			const selected = { ...clientPayer.value, isSelected: value }
			context.emit('selected', selected)
		}

		return {
			isEditingTransactions,
			isEditingPricing,
			isEditingAliases,
			isEditingPortals,
			isSavingTransactions,
			clientPayer,
			removeClientPayer,
			saveClientPayer,
			handleOnSelect,
			getV2Route,
		}
	},
})
