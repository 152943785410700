
import {
	defineComponent,
	SetupContext,
	watch,
	watchEffect,
	ref,
	onMounted,
} from 'vue'
import { useStore } from '@/utils/composables'

import FuzzySearch from 'fuzzy-search'
import { ICPT } from '@server/api/models/fe/shared'

export default defineComponent({
	props: {
		value: Object,
		disabled: Boolean,
		labelLength: Number,
		placeholder: String,
		label: String,
		onlyClientCPTs: Boolean,
		selectedCPTs: Array,
		displayType: String,
	},
	setup(props: any, context: SetupContext) {
		const store = useStore()
		const selectedCpt = ref(props.value)
		const selectedCPTs = ref(props.selectedCPTs)
		const labelLength = ref(props.labelLength || 40)
		const filteredCPTs = ref<any>()

		let availableCptsForPayer: ICPT[] = []
		const setCptList = () => {
			const alreadySelected = selectedCPTs.value?.map(
				(cpt: any) => cpt.code || cpt.procedureCode
			)

			if (props.onlyClientCPTs) {
				availableCptsForPayer = store.state.client.procedures
					.procedures as ICPT[]
			} else {
				availableCptsForPayer = store.state.cpts.all
			}

			availableCptsForPayer = availableCptsForPayer.filter(
				cpt => !alreadySelected?.includes(cpt.code)
			)
		}

		function onInput(cpt: ICPT): void {
			context.emit('input', cpt)
		}

		const filter = (search: string) => {
			const searcher = new FuzzySearch(
				availableCptsForPayer,
				['code', 'description'],
				{
					caseSensitive: false,
				}
			)
			return searcher.search(search).slice(0, 50)
		}

		onMounted(() => {
			setCptList()
			filteredCPTs.value = filter('')
		})

		async function onSearch(search: string) {
			await store.dispatch('cpts/fetchCPTs', search)
			setCptList()
			filteredCPTs.value = filter(search)
		}

		watchEffect(() => {
			selectedCpt.value = props.value
			labelLength.value = props.trimLabelTo
			selectedCPTs.value = props.selectedCPTs
		})

		watch(selectedCPTs, _ => {
			setCptList()
			filteredCPTs.value = filter('')
		})

		function getLabel(cpt: ICPT) {
			if (cpt.description?.length === 0 || !cpt.description) {
				return cpt.code
			}
			const formattedLabel = `${cpt.code} - ${cpt.description}`
			if (formattedLabel.length > labelLength.value) {
				return formattedLabel.substring(0, labelLength.value) + '...'
			}
			return formattedLabel
		}

		return {
			onInput,
			onSearch,
			filteredCPTs,
			getLabel,
			selectedCpt,
		}
	},
})
